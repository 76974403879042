import React from "react";
import { useTranslation } from "react-i18next";

const References = () => {
  const { t } = useTranslation();
  const productIndexes = [
    ["1", "Antalya Reçelcisi"],
    ["2", "Avokadolu"],
    ["3", "Doğa Tarım"],
    ["4", "Merkez Anadolu Kimya"],
    ["5", "Kraftech Auto Care"],
    ["6", "Ergıda Tarım"],
    ["7", "Pafilya"],
    ["8", "Geofarm"],
    ["9", "Frupan"],
  ];
  return (
    <section className="min-h-[92vh]  xl:w-[96vw] md:w-[96vw] mx-auto border-t pt-[3vh]">
      <h1 className="md:text-[2rem] max-md:text-center  max-md:text-[1.7rem] font-[400]">
        {t("menuItem5")}
      </h1>
      <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 cols gap-5 max-md:px-5  font-[300] pt-5 pb-[15vh]">
        {productIndexes.map((val) => (
          <div
            key={val}
            className="col-span-1  border border-gray-300 overflow-hidden"
          >
            <img
              src={`/images/referencesImages/${val[0]}.jpg`}
              alt={val[1]}
              className="w-full h-[35vh] object-contain"
            />
            {/* object-fit class'ı ver yukardaki img' tag'ine */}
            <h2 className="text-center font-[400] py-2  md:text-2xl max-md:text-xl">
              {val[1]}
            </h2>
            {/* <p className="px-5 py-5">{t(`productDescription${val[1]}`)}</p> */}
          </div>
        ))}
      </div>
    </section>
  );
};

export default References;
